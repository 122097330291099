$white: #ffffff;
$text-color: #4d4d4d;

.flag-select {
  position: relative;
  display: inline-block;
  vertical-align: inherit;
  padding-bottom: 5px;
  text-align: left;

  &__btn {
    padding: 0 8px;
    color: $text-color;
    border: none;
    background: transparent;

    &:after, &[aria-expanded="true"]:after {
      content: " ";
      width: 0;
      height: 0;
      display: inline-block;
      vertical-align: middle;
      margin-left: 5px;
    }

    &:after {
      border-top: 5px solid $text-color;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-bottom: 0;
    }

    &[aria-expanded="true"]:after {
      border-top: 0;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-bottom: 5px solid $text-color;
    }

    &:before {
      content: " ";
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: 999;
    }
  }

  &__option {

    &s {
      position: absolute;
      z-index: 999999;
      border: 1px solid #bdbbbb;
      border-radius: 3px;
      background: $white;
      margin-top: 8px;
      padding: 8px 0;
      max-height: 160px;
      overflow: auto;

      &.to--left {
        right: 10px;
      }
    }

    cursor: pointer;
    padding: 0 8px 3px 8px;
    margin: 4px 0;
    white-space: nowrap;

    &--placeholder {
      height: inherit;
      width: inherit;
      display: inline-block;
      vertical-align: middle;
    }

    &:not(&--placeholder) {
      &:hover, &:focus {
        outline: none;
        background: #eaeaea;
      }
    }

    &.has-label {
      padding: 0 20px 3px 8px;
    }

    &__label {
      font-size: 1em;
      position: relative;
      padding-left: 9px;
    }

    &__icon {
      width: 1.3em;
      height: 1.3em;
      position: relative;
      top: 0.3em;
    }
  }

  .filterBox {
    width: 100%;

    input {
      width: 90%;
      margin: 0 4%;

      &:focus {
        outline: none
      }
    }
  }

  .hidden {
    display: none;
  }

  .no--focus {
    pointer-events: none;
  }
}
